<template>
    <div class="d-flex flex-column flex-root vh100">
			<div class="login login-5 login-signin-on d-flex flex-row-fluid" id="kt_login">
				<div class="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"  v-bind:style="{ 'background-image': 'url(' + bg_image + ')' }">
					<div class="login-form text-center text-white p-7 position-relative overflow-hidden">
						<div class="d-flex flex-center mb-15">
							<a href="#">
								<img src="@/assets/images/logo-big.png" class="max-h-75px" alt="" />
							</a>
						</div>
						<div class="login-signin">
							<div class="mb-20">
								<h3 class="font-weight-normal">Sign In To Admin</h3>
								<p class="">Enter your details to login to your account:</p>
							</div>
        					<Message  v-if="is_error" severity="error">Invalid credentials, Please try again...</Message>
							<form class="form" @submit.prevent="login"  method="post" id="kt_login_signin_form">
								<div class="form-group">
									<input class="form-control h-auto rounded-pill border-0 py-4 px-8" type="text" placeholder="Email" name="email" v-model="email" autocomplete="off" />
								</div>
								<div class="form-group">
									<input class="form-control h-auto rounded-pill border-0 py-4 px-8" type="password" placeholder="Password" name="password" v-model="password" />
								</div>
								<div class="form-group d-flex flex-wrap justify-content-between align-items-center px-8">
									<div class="checkbox-inline">
										<label class="checkbox checkbox-outline checkbox-white text-white m-0">
										<input type="checkbox" name="remember" />
										<span></span>Remember me</label>
									</div>
									<a href="javascript:void(0);" @click="forgot"  class="text-white font-weight-bold">Forget Password ?</a>
								</div>
								<div class="form-group text-center mt-10">
									<button  :disabled="vm.loading" type="submit" id="kt_login_signin_submit" class="btn btn-pill btn-primary opacity-90 px-15 py-3">
										<span v-if="!vm.loading">Sign In</span>
										<span v-else> <i class="fas fa-spinner fa-spinner"></i> Signing In....</span>
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
</template>
<script>
import { ref, onMounted, reactive, inject } from 'vue'
import Message from 'primevue/message';
export default {
	components: { Message },
    setup() {
		const { appRouter, router, route, constants, http, store } = inject('App')
		
		const is_forgot = ref(false)
		const bg_image = require('@/assets/themes/assets/media/bg/bg-2.jpg')
		const email = ref('')
		const password = ref('')
		const is_error = ref(false)

		const vm = reactive({
			loading: false
		})
		async function login() {
			vm.loading = true;
			is_error.value = false
            const f = new URLSearchParams()
            f.append('email', email.value)
            f.append('password', password.value)
			store.dispatch('UserModule/AUTH_LOGIN', [f, constants, http])
				.then(resp => {
				vm.loading = false;
				const status = resp.data.status
				
				
				if (status == 1) {
					const user =  resp.data.data.user
					if (user.user_type == 'teacher')	
							appRouter.routeTo(route, router, 'Dashboard')
					else if (user.user_type == 'school')
							appRouter.routeTo(route, router, 'Dashboard')
					else 
						appRouter.routeTo(route, router, 'School List')
				}
				}).catch(err => {
				is_error.value = true
				vm.loading = false
			});
		}
		function forgot(n) {
            appRouter.routeTo(route, router, 'Forgot Password')
        }
		onMounted(() => {
        })
		return {
			 is_forgot, bg_image,
			 email, password,
			 vm, 
			 login,
			 is_error,
			 forgot
		}
	},	
}
</script>
<style scoped src="@/assets/themes/assets/css/pages/login/classic/login-5.css"></style> 